import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

window.addEventListener('load', ()=>{
    cardAnimations();
})

function cardAnimations() {
    const block = document.querySelector('.projects-block');

    if (block) {
        const cards = block.querySelectorAll('.project');

        cards.forEach((card, id) => {
            if (id % 2 === 0) {
                gsap.from(card, {
                    scrollTrigger: card,
                    x: -800,
                    // transitionDuration: '2s',
                })
            }
            else {
                gsap.from(card, {
                    scrollTrigger: card,
                    x: 800,
                    // transitionDuration: '6s',
                })
            }            
        });
    }
}